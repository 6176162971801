import {
    createSocketManager,
    isWebSocketClosedWithError,
    SocketCloseEventCode,
} from '@pexip/socket-manager';
import type {
    DisconnectStream,
    JoinMeeting,
    RequestStream,
    RequestStreamResponse,
} from '@pexip/vpaas-api';
import {createApi, GoneError, NotFoundError} from '@pexip/vpaas-api';
import type {Detach} from '@pexip/signal';
import type {TransceiverConfig, TransceiverInit} from '@pexip/peer-connection';

import type {Call} from './call';
import {createCall} from './call';
import {logger} from './logger';
import {
    createSocketSignals,
    isResponseError,
    MeetingFullError,
    ResourceUnavailableError,
    retriable,
    WebsocketError,
} from './utils';
import {VpaasError} from './types';
import type {
    SocketManager,
    Vpaas,
    VpaasConnectArgs,
    VpaasSignals,
    SocketSignals,
    VpaasConfig,
    VpaasJoinArgs,
    VpaasProps,
    Ref,
    Handler,
} from './types';
import {MAX_RECONNECT_ATTEMPTS} from './constants';

/**
 * Creates MEE wrapper for the given `apiAddress` and `userID`
 *
 * @param args - Provides necessary information like `apiAddress` and `userId`
 * @returns Wrapper which encapsulates all interactions with MEE backend including WEBRTC
 */
export const createVpaas = ({
    vpaasSignals,
    config,
    socketSignals = createSocketSignals(),
}: {
    vpaasSignals: VpaasSignals;
    config: VpaasConfig;
    socketSignals?: SocketSignals;
}): Vpaas => {
    const props: VpaasProps = {
        trace: config.trace,
        sendCandidates: config.sendCandidates ?? false,
        mediaInits: [],
    };
    const {trace} = props;
    const api = createApi();
    const promiseMap = new Map<Ref, Handler>();

    let socket: SocketManager;
    let call: Call | undefined;
    let detachSignals: Detach[] = [];
    let rejoinAttempt = 0;

    socketSignals.onMessage.add(msg => {
        const ref = msg.ref ?? '';
        const promiseHandlers = promiseMap.get(ref);
        if (!promiseHandlers) {
            return;
        }

        if (msg.type === 'error' || msg.type === 'server_error') {
            promiseHandlers.reject(msg);
        } else {
            promiseHandlers.resolve(msg);
        }

        promiseMap.delete(ref);
    });

    const send = (params: Parameters<typeof socket.send>[0]) => {
        const ref = socket.send({...params, trace});

        const promise = new Promise<typeof params>((resolve, reject) => {
            ref && promiseMap.set(ref, {resolve, reject});
        });

        return promise;
    };

    const joinMeeting = async (args: VpaasJoinArgs) => {
        const {
            apiAddress,
            abortController,
            meetingId,
            participantId,
            participantSecret,
            retry,
        } = args;
        socket = createSocketManager(socketSignals);
        let meetingDetails: JoinMeeting | undefined;

        try {
            logger.debug({meetingId, participantId}, 'Join Meeting');

            const _join = async (retry = false) => {
                const abortSignal = abortController?.signal;
                const join = () =>
                    api.join({
                        abortSignal,
                        apiAddress,
                        meetingId,
                        participantId,
                        participantSecret,
                        headers: {
                            ...trace,
                        },
                    });

                const data =
                    meetingDetails ??
                    (await (retry ? retriable(join) : join())).data;
                meetingDetails = data;

                await socket.connect({
                    url: data.location,
                    abortController,
                });

                return data;
            };

            const _authenticate = async (data: JoinMeeting) => {
                try {
                    await send({
                        type: 'authenticate',
                        token: data.token,
                        participant_id: participantId,
                        trace,
                    });
                } catch (e) {
                    if (isResponseError(e)) {
                        if (e.error_type === 'resource_unavailable') {
                            if (e.error_message === 'Meeting is full') {
                                throw new MeetingFullError(e.error_message);
                            }
                            throw new ResourceUnavailableError(
                                e.error_message ?? e.error_type,
                            );
                        }

                        throw new WebsocketError(
                            e.error_message ?? e.error_type,
                        );
                    }
                    throw new WebsocketError('Unknown error');
                }
            };

            await _authenticate(await _join(retry));

            const _reconnect = async () => {
                try {
                    logger.debug('Reconnecting');
                    const _tryReconnect = async () => {
                        disconnect();
                        await _authenticate(await _join(true));
                    };

                    vpaasSignals.onReconnecting.emit();
                    await retriable(_tryReconnect);
                    if (call) {
                        connect(props);
                    }
                    vpaasSignals.onReconnected.emit();
                    logger.debug('Reconnected');
                } catch (error) {
                    logger.error({error}, 'Reconnecting failed');
                    vpaasSignals.onError.emit(VpaasError.RECONNECTION_FAILED);
                }
            };

            const _rejoin = async () => {
                try {
                    logger.debug('Rejoining');
                    vpaasSignals.onReconnecting.emit();
                    await retriable(() => joinMeeting({...args, retry: true}));
                    if (call) {
                        connect(props);
                    }
                    rejoinAttempt = 0;
                    vpaasSignals.onReconnected.emit();
                    logger.debug('Rejoined');
                } catch (error) {
                    logger.error({error}, 'Rejoining failed');
                    vpaasSignals.onError.emit(VpaasError.RECONNECTION_FAILED);
                }
            };

            detachSignals.push(
                socketSignals.onReconnected.add(async () => {
                    try {
                        logger.debug(
                            'Websocket reconnected. Try reauthenticating',
                        );
                        if (meetingDetails) {
                            vpaasSignals.onReconnecting.emit();
                            await _authenticate(meetingDetails);
                            if (call) {
                                connect(props);
                            }
                            vpaasSignals.onReconnected.emit();
                        }
                    } catch (error) {
                        logger.error(
                            {error},
                            'Reauthenticating failed. Try reconnecting',
                        );
                        await _reconnect();
                    }
                }),
            );

            detachSignals.push(
                socketSignals.onDisconnected.add(async ({code}) => {
                    if (!isWebSocketClosedWithError(code)) {
                        return;
                    }

                    rejoinAttempt++;
                    if (rejoinAttempt < MAX_RECONNECT_ATTEMPTS) {
                        logger.error('Websocket disconnected. Try rejoining');
                        disconnect();
                        await _rejoin();
                    } else {
                        logger.error("Websocket disconnected. That's it.");
                    }
                }),
            );
        } catch (error) {
            if (error instanceof Error && error.name !== 'AbortError') {
                disconnect();

                if (error instanceof GoneError) {
                    vpaasSignals.onError.emit(VpaasError.MEETING_EXPIRED);
                } else if (error instanceof NotFoundError) {
                    vpaasSignals.onError.emit(VpaasError.MEETING_NOT_FOUND);
                } else if (error instanceof MeetingFullError) {
                    vpaasSignals.onError.emit(VpaasError.MEETING_FULL);
                } else {
                    vpaasSignals.onError.emit(VpaasError.CONNECTION_FAILED);
                }
                throw error;
            }
        }
    };

    const connect = ({mediaInits, abortController}: VpaasConnectArgs) => {
        props.mediaInits = mediaInits;

        if (call) {
            call.close();
        }
        call = createCall({
            abortController,
            meeSignals: vpaasSignals,
            socket,
            socketSignals,
            ...props,
        });
    };

    const disconnect = () => {
        socket.disconnect(SocketCloseEventCode.NormalClosure);
        call?.close();

        detachSignals.forEach(detach => detach());
        detachSignals = [];
    };

    const requestStream = async (
        args: RequestStream,
    ): Promise<RequestStreamResponse> => {
        const res = await send({type: 'request_stream', ...args});
        if (res.type !== 'request_stream_response') {
            throw new Error(res.type ?? 'Unexpected response type');
        }
        return res;
    };

    const disconnectStream = async (
        args: DisconnectStream,
    ): Promise<undefined> => {
        await send({type: 'disconnect_stream', ...args});
    };

    return {
        getTransceiverConfigs() {
            return call?.pc.getTransceiverConfigs() ?? [];
        },
        addConfig(initOrConfig: TransceiverInit | TransceiverConfig) {
            return call?.pc.addConfig(call?.pc.peer, initOrConfig);
        },
        setStream(stream: MediaStream) {
            call?.setStream(stream);
        },
        present(presentationStream: MediaStream) {
            call?.present(presentationStream);
        },
        stopPresenting() {
            call?.stopPresenting();
        },
        joinMeeting,
        connect,
        disconnect,
        requestStream,
        disconnectStream,
    };
};
