import type {
    DisconnectStream,
    VpaasWebsocketMessageTypesUnion,
    RequestStream,
    RequestStreamResponse,
    RosterUpdate,
    ServerError,
    Error as ResponseError,
    MediaOffer,
} from '@pexip/vpaas-api';
import type {
    BasePeerConnection,
    MediaInit,
    TransceiverConfig,
    TransceiverInit,
} from '@pexip/peer-connection';
import type {Signal} from '@pexip/signal';
import type {
    createSocketManager,
    createSocketSignals,
} from '@pexip/socket-manager';

export type SocketManager = ReturnType<
    typeof createSocketManager<VpaasWebsocketMessageTypesUnion>
>;

export type Roster = RosterUpdate['participants'];
export type RosterEntry = RosterUpdate['participants']['streams'];

export interface VpaasJoinArgs {
    apiAddress: string;
    /**
     * An id of the meeting we want to join
     */
    meetingId: string;
    abortController?: AbortController;
    participantId: string;
    participantSecret: string;
    retry?: boolean;
}

export interface VpaasConnectArgs {
    abortController?: AbortController;
    mediaInits: MediaInit[];
}

export interface Vpaas {
    /**
     * Joins to the existing meeting
     * @param args - An object containing necessary information for joining to the meeting
     */
    joinMeeting(args: VpaasJoinArgs): Promise<void>;

    /**
     * Makes webrtc call to the existing meeting
     * @param args - An object containing necessary information for connecting to the meeting
     */
    connect(args: VpaasConnectArgs): void;

    /**
     * Disconnects from WebSocket and closes the Webrtc call
     */
    disconnect(): void;

    requestStream(args: RequestStream): Promise<RequestStreamResponse>;

    disconnectStream(args: DisconnectStream): Promise<undefined>;

    getTransceiverConfigs(): TransceiverConfig[];
    addConfig(
        initOrConfig: TransceiverInit | TransceiverConfig,
    ): TransceiverConfig | undefined;
    setStream(stream: MediaStream): void;
    present(presentationStream: MediaStream): void;
    stopPresenting(): void;
}

export interface Call {
    pc: BasePeerConnection;

    close(): void;
}

export type {RosterUpdate, VpaasWebsocketMessageTypesUnion};
export type Filter = (config: TransceiverConfig) => boolean;
export type SocketSignals = ReturnType<
    typeof createSocketSignals<VpaasWebsocketMessageTypesUnion>
>;

export enum VpaasError {
    CONNECTION_FAILED = 'CONNECTION_FAILED',
    AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
    MEETING_FULL = 'MEETING_FULL',
    RECONNECTION_FAILED = 'RECONNECTION_FAILED',
    MEETING_EXPIRED = 'MEETING_EXPIRED',
    MEETING_NOT_FOUND = 'MEETING_NOT_FOUND',
}
export interface VpaasSignals {
    onRosterUpdate: Signal<RosterUpdate['participants']>;
    onMediaOffer: Signal<MediaOffer>;
    onReconnecting: Signal<undefined>;
    onReconnected: Signal<undefined>;
    onRemoteStreams: Signal<TransceiverConfig>;
    onError: Signal<VpaasError>;
}

export interface VpaasConfig {
    trace?: {
        traceparent?: string;
        tracestate?: string;
    };
    sendCandidates?: boolean;
}

export interface VpaasProps extends VpaasConfig {
    mediaInits: MediaInit[];
}

export type Ref = string;
export interface Handler {
    resolve: (msg: VpaasWebsocketMessageTypesUnion) => void;
    reject: (msg: ResponseError | ServerError) => void;
}
